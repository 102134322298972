.loka-wrapper {
    position: relative;
    width: 100%;
    background-color: var(--eerie-black-1);
    font-family: var(--fontFamily-forum);
    padding-top: 0.1rem;
}
.loka-wrapper h2{
    position: absolute;
    top: 5%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    color: var(--mainColor);
    font-family: var(--fontFamily-toplar);
    font-size: 3rem;
}
.loka-wrapper h4{
    position: relative;
    top: 30%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    color: var(--white );
    font-size: 2rem;
    margin: 2rem 0;
}
@media screen and (max-width: 1000px) {
    .loka-wrapper h2{
        top: 20px;
        font-size: 2rem;
    }
    .loka-wrapper h4{
        top: 10%;
        font-size: 1.2rem;
    }
  }
.loka-background {
    background-image: url(../../images/parkloka7.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    filter: blur(2px);
    min-height: 100vh;
}
@media screen and (max-width: 1000px) {
    .loka-background {
        min-height: 110vh;
    }
  }

.loka-container {
    position: absolute;
    justify-content: center; /* Center align the items vertically */
    align-items: center; /* Center align the items horizontally */
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 70vw;
    min-height: 100vh;
    display: flex;
    flex-direction: row;
    gap:30px;
    z-index: 2;

}
@media screen and (max-width: 980px) {
    .loka-container {
        top: 5vh;
        width: 60vw;
        flex-direction: column;
        min-height: 100vh;
        max-height: 110vh;
        gap:0px;
    }
}
@media screen and (max-width: 700px) {
    .loka-container {
        top: 5vh;
        width: 80vw;
        flex-direction: column;
        min-height: 100vh;
        max-height: 110vh;
        gap:0px;
    }
  }

@media screen and (min-width: 2560px) {
    .loka-container {
        width: 50%;
    }
}
.loka-card {
    display: flex;
    flex-direction: column; /* Ensure the children are stacked vertically */
    justify-content: center; /* Center align the items vertically */
    align-items: center; /* Center align the items horizontally */
    width: 100%;
    height: 100%;
    margin: 0;
}
.loka-card:hover {
    scale: 1.01;
    transform: translateY(-10px);
    filter: drop-shadow(8px 8px 10px gray);
    transition: 0.5s ease;
    cursor: pointer;
}
.loka-card img{
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.loka-text{
    position: relative;
    top: -2rem;
    width: 100%;
    height: 2rem;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    font-size: var(--fontSizePc);
    text-align: center;
}

.loka-item {
    background-color: var(--smoky-black-1);
    width: 80%;
    min-height: 120vh;
    height: auto;
    padding: 0 0.5rem;
    color: var(--mainColor);
    font-size: var(--fontSizePc);
    display: none;
}

.show-item {
    display: block;
    padding: 1rem 0;
}
.loka-item h2{
    position: relative;
    font-family: var(--fontFamily-toplar);
    margin-top: 1rem;
}
.loka-item img{
    width: 50%;
    max-width: 800px;
    height: auto;
    padding: 2rem;
}
.loka-flex {
    width: 100%;
    display: flex;
    flex-direction: row;
}
.loka-flex p, .loka-flex pre{
    color: var(--mainColor);
    font-size: 1.4rem;
    padding: 1rem;
    width: 50%;
    font-family: inherit; 
    white-space: pre-wrap; 
    word-wrap: break-word; 
    background: none; 
    border: none; 
}
.loka-galerija {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.loka-galerija p{
    color: var(--mainColor);
    font-size: 2rem;
    padding: 2rem 0 0 0;
    width: 20%;
}

@media screen and (max-width: 1000px) {
    .loka-item {
        width: 100%;
    }
    .loka-item img{
        width: 100%;
        padding: 2rem 1rem;
    }
    .loka-flex{
        flex-direction: column;
    }
    .loka-flex p,.loka-flex pre{
        width: 100%;
        font-size: 1.2rem;
        padding: 2rem;
    }
    .loka-galerija p{
        width: 100%;
        font-size: 1.5rem;
    }
  }
.second {
    background-color: var(--smoky-black-1);
}
