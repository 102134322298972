.slider-show {
  position: relative;
  width: 100%;
  height: 100svh;
  overflow: hidden;
}

.image {
  position: absolute;
  left: 0%;
  width: 100%;
  height: 100svh;
  object-fit: cover;
}

.menuBtnSlider {
  position: absolute;
  bottom: 20vh;
  left: 50%;
  transform: translateX(-50%);
  width: 25%;
  margin-right: auto;
  margin-left: auto;
  z-index: 10; /* Ensure it's above the images */
}

.menuBtnSlider button{
  width: 100%;
  height: 3rem;
  border: 1px solid var(--mainColor);
  border-radius: 4px;
  background-color: var(--transparentBlackColor);
  color:var(--mainColor);
  font-family: var(--fontFamily-forum);
  font-size: 1rem;
  cursor: pointer;
}

.menuBtnSlider button:hover{
  background-color: var(--mainColor);
  color:var(--transparentBlackColor);
}

.slider-controls button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
  color: var(--mainColor);
  border: none;
  cursor: pointer;
  padding: 10px;
  height: 2rem;
  width: 2rem;
  padding: .5rem;
  font-size: 1rem;
  background-color: #00000080;
  border-radius: .5rem;
}

.slider-controls button:first-of-type {
  left: 10px;
}

.slider-controls button:last-of-type {
  right: 10px;
}

.slider-dots {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
}

.dot {
  cursor: pointer;
  height: 10px;
  width: 10px;
  margin: 0 5px;
  background-color: var(--mainColor);
  border-radius: 50%;
  display: inline-block;
}

.dot.active {
  background-color: var(--white);
}

.slider-text-container {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;       /* Added this */
  justify-content: center;   /* Added this */
  text-align: center;        /* Ensures text is centered if it wraps */
}
.slider-text-container p{
  width: auto;
  font-size: 3rem;
  margin: 0;
  color:var(--mainColor);
  font-family: var(--fontFamily-forum);
  text-shadow: 1px 1px #000;
  padding-bottom: 1rem;
  padding-right: 1rem;
}
.slider-text-container img{
  width: 10%;
  margin: 0;
  align-items: center;
  justify-content: center;
  height: 4rem;
  width: 4rem;
}
@media screen and (max-width: 1000px) {
  .slider-text-container {
    flex-direction: column;
  }
  .slider-text-container p{
    font-size: 2.2rem;
    width: 70%;
  }  
  .slider-text-container img{
    align-items: center;
    justify-content: center;
    height: 6rem;
    width: 6rem;
  }
}