.onas-wrapper {
  position: relative;
  min-height: 50vh;
  width: 100%;
  background-color: var(--eerie-black-1);
  color: var(--mainColor);
  font-family: var(--fontFamily-forum);
  padding: 1rem 0rem;
}

@media screen and (min-width: 2560px) {
  .onas-wrapper {
    padding: 1rem 0rem;
  }
}

.onas-shadow {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}
.onas-flex {
  width: 100%;
  max-width: 1920px;
  height: 100vh;
  display: flex;
  flex-direction: row;
  background-position: center;
  background-size: cover;
}
.first-image {
  background-image: url("../../images/toplar22.jpg");
}
.second-image {
  background-image: url("../../images/toplar29.jpg");
}
.third-image {
  background-image: url("../../images/toplar17.jpg");
}
.forth-image {
  background-image: url("../../images/parkloka7.jpg");
}
.onas-flex p,
.onas-flex pre {
  padding: 1rem;
  width: 50%;
  height: 100%;
  color: var(--white);
  text-align: center;
  margin: auto;
  font-size: 1.4rem;

  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 1000px) {
  .onas-flex {
    width: 100%;
    flex-direction: column;
  }
  .onas-flex p,
  .onas-flex pre {
    width: 100%;
    font-size: var(--fontSizePc);
  }
}
