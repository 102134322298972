.scrollBtnSlider {
    position: fixed;
    bottom: 1rem;
    right: 5%;
    height: 3rem;
    width: 3rem;
    z-index: 999;
    border-radius: 50%;
    transition: background 0.3s ease;
    display: grid;
    place-items: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.scrollButton {
    position: relative;
    top: 0.5rem;
    height: calc(100% - 1rem);
    width: calc(100% - 1rem);
    background-color: var(--mainColor);
    color: var(--eerie-black-1);
    border: none; /* Remove border from button */
    border-radius: 50%;
    display: grid;
    place-items: center;
    cursor: pointer;
}

.scrollButton:hover {
    background-color: var(--eerie-black-1);
    color: var(--mainColor);
}

.scrollButton i {
    pointer-events: none;
    color: var(--eerie-black-1);
}

@media screen and (max-width: 1000px) {
    .scrollButton:hover {
        background-color: #ffffff;
    }
    .scrollButton i:hover {
        color: var(--eerie-black-1);
    }
}

.show {
    display: block;
}

.hide {
    display: none;
}
