.loka-slider-show {
  position: relative;
  width: 50%;
  height: 40vh;
  overflow: hidden;
  align-items: center;
  margin: 0 auto;
}
@media screen and (max-width: 1000px) {
  .loka-slider-show{
    height: 30vh;
    min-height: 45vh;
    width: 100%;
  }  
}
@media screen and (min-width: 1920px) {
  .loka-slider-show{
    height: 20vw;
  }  
}
.loka-slider-show img{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: contain; 
}


.loka-menuBtnSlider {
  position: absolute;
  bottom: 20vh;
  left: 50%;
  transform: translateX(-50%);
  width: 25%;
  margin-right: auto;
  margin-left: auto;
  z-index: 10; /* Ensure it's above the images */
}

/* .loka-menuBtnSlider button{
  width: 100%;
  height: 3rem;
  border: 1px solid var(--mainColor);
  border-radius: 4px;
  background-color: var(--transparentBlackColor);
  color:var(--mainColor);
  font-family: var(--fontFamily-forum);
  font-size: 1rem;
  cursor: pointer;
}

.loka-menuBtnSlider button:hover{
  background-color: var(--mainColor);
  color:var(--transparentBlackColor);
}

.loka-slider-controls span {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
  color: var(--white);
  border: none;
  cursor: pointer;
  padding: 10px;
  height: 2.5rem;
  width: 2.5rem;
}

.loka-slider-controls button:first-of-type {
  left: 10px;
}

.loka-slider-controls button:last-of-type {
  right: 10px;
} */

.loka-slider-dots {
  position: absolute;
  bottom: 45px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
}

.loka-dot {
  cursor: pointer;
  height: 35px;
  width: 35px;
  margin: 0 5px;
  border-radius: 50%;
  border: 1px solid white;
  display: inline-block;
}
@media screen and (max-width: 1000px) {
  .loka-slider-dots {
    bottom: 5px;
  }
  .loka-dot {
    height: 20px;
    width: 20px;
  }
}
.loka-dot.active {
  background-color: var(--white);
}

.loka-slider-text-container {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
  width: 100%;
}
.loka-slider-text-container p{
  width: 70%;
  font-size: 2rem;/* 
  border: 1px solid var(--mainColor);
  border-radius: 4px; 
  background-color: var(--transparentBlackColor);
  padding: 0.5rem;*/
  color:var(--mainColor);
  font-family: var(--fontFamily-forum);
  text-shadow: 1px 1px #000;
  padding-bottom: 1rem;
}
.loka-slider-text-container h2{
  color:var(--white);
  font-size: 2.6rem;
  text-shadow: 1px 1px #000;
}
@media screen and (max-width: 1000px) {
  .loka-slider-text-container p{
    font-size: 1.2rem;
  }  
  .loka-slider-text-container h2{
    font-size: 1.8rem;
  }
}