.meni-wrapper {
    position: relative;
    min-height: 50vh;
    width: 100vw;
    justify-content: center;
    align-items: center;
    background-color: var(--eerie-black-1);
    color: var(--mainColor);
    font-family: var(--fontFamily-forum);
    padding: 1rem 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}
@media screen and (min-width: 2560px) {
    .meni-wrapper{
        width: 50vw;
    }
}

@media screen and (max-width: 1000px) {
    .meni-wrapper {
        flex-direction: column;
    }
}
/* -------------------------IMAGE------------------- */
.image-meni {
    width: 45%;
    margin: auto 1rem;
    align-items: center;
}
.image-meni h1 {
    position: relative;
    top: 0px;
    color: white;
    background-color: var(--transparentBlackColor);
    width: 50%;
    border-radius: 4px;
}
.image-meni-sub {
    border-radius: 20px;
    width: 100%;
    height: auto;
    max-height: 80vh;
    margin-bottom: 2rem;
}
@media screen and (max-width: 1000px) {
    .image-meni {
        width: 90vw;
        margin: 0 1rem;
        align-items: center;
        justify-content: center;
    }
    .image-meni h1 {
        width: 50%;
    }
}
/* -------------------------PIZZA------------------- */
.meni-pizza {
    display: flex;
    flex-grow: 2;
    margin-bottom: 1rem;
    padding: 0;
    width: 50%;
}
.meni-velikosti-left {
    width: 60%;
    padding: 0 1rem;
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
}

.pizza-lines {
    width: 100%;
    border-top: 3px dotted var(--mainColor);
    border-bottom: 0px;
}

.lines {
    width: 60px;
    display: flex;
    flex-direction: row;
    margin: 0;
    align-items: center;
    justify-content: center;
 }
 
.line-right{
    width: var(--lineRadius);    
    border: 1px solid var(--mainColor);
    height: var(--lineRadius);
    border-style: solid;
    border-color: var(--mainColor);
    border-width: 1px 0 0 0;
    border-radius: var(--lineRadius);
    margin: 0;
}
.line-right:before{
    display: block;
    content: "";
    height: var(--lineRadius);
    margin-top: -21px;
    border-style: solid;
    border-color: var(--mainColor);
    border-width: 0 0 1px 0;
    border-radius: var(--lineRadius);
}
.line-left{
    width: var(--lineRadius);    
    border: 1px solid var(--mainColor);
    height: var(--lineRadius);
    border-style: solid;
    border-color: var(--mainColor);
    border-width: 0 0 1px 0;
    border-radius: var(--lineRadius);
    margin: 0;
}
.line-left:before{
    display: block;
    content: "";
    height: var(--lineRadius);
    margin-top: -21px;
    border-style: solid;
    border-color: var(--mainColor);
    border-width: 1px 0 0 0;
    border-radius: var(--lineRadius);
}
.heart-image-meni {
    width: 3.5rem;
    height: 3.5rem;
    padding: 0 0;
    margin: 0 1.5rem;
}
.heart-image-meni img{
    width: 100%;
    height: 100%;
}
.meni-velikosti {
    display: flex;
    width: 30%;
    align-items: center;
}
.show-pizza {
    display: flex;
}
.pizza-line {
    width: 50%;
    max-width: 50%;
    padding: 0 0.5rem;
    margin: 0;
    /*  */
    
}

.pizza-line hr{
    width: 100%;    
    border: 1px solid var(--mainColor);
    height: 30px;
    border-style: solid;
    border-color: var(--mainColor);
    border-width: 1px 0 0 0;
    border-radius: 20px;
}
.pizza-line hr:before {
    display: block;
    content: "";
    height: 30px;
    margin-top: -31px;
    border-style: solid;
    border-color: var(--mainColor);
    border-width: 0 0 1px 0;
    border-radius: 20px;
}
@media screen and (max-width: 1000px) {  
    .meni-pizza {
        width: 10%;
    }
    .meni-velikosti {
        width: 40%;
    }
    .meni-velikosti h2{
        padding-left: 0.7rem;
    }
    .pizza-line {
        width: 30%;
    }
    .show-pizza {
        display: none;
    }
    .pizza-cena  {
        padding-left: 0.7rem;
    }
}
/* -------------------------KARTICA-ITEM------------------- */
.meni-item {
    width: 50%;
    display: none;
    padding: 0 2rem;
}

.meni-item h1{
    margin-bottom: 1rem;
}

.meni-item.show {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}
@media screen and (max-width: 1000px) {
    .meni-item {
        width: 100%;
        padding: 0.5rem;
    }
    .meni-item.show {
        flex-direction: column;
    }
}
.meni-flex {
    width: 100%;
    font-family: var(--fontFamily-toplar);
}

.meni-flex h1{
    font-size: 3.5rem;
}
@media screen and (max-width: 1000px) {
    .meni-flex {
        margin-top: 1rem;
    }
    .meni-flex h1{
        font-size: 2.5rem;
    }
}
/* -------------------------CONTENT------------------- */
.meni-divide {
    display: flex;
    font-family: var(--fontFamily-forum);
    flex-wrap: wrap;
    width: 100%;
    margin-top: 1rem;
}

.meni-content {
    flex-grow: 2;
    width: 50%;
    max-width: 50%;
    display: flex;
    flex-direction: column;
    background-color: var(--transparentBlackColor1);
    border-radius: 4px;
    padding: 0.3rem;
    color: var(--white);
    margin: 1rem 0;
}

.meni-content-burger{
    display: flex;
    flex-direction: row;
    width: 100%;
}

.meni-content p{

    color: var(--grey);
}

.meni-content-up {
    flex-grow: 2;
    width: 50%;
    max-width: 50%;
    display: flex;
    flex-direction: row;
    background-color: var(--transparentBlackColor1);
    border-radius: 4px;
    padding: 0.3rem;
    color: var(--white);
    margin: 1rem 0;
}

@media screen and (max-width: 1000px) {
    
    .meni-content {
        flex-grow: 1;
        padding: 0.5rem;
        width: 100%;
        max-width: 100%;
    }
    .meni-content-up{
        width: 100%;
        max-width: 100%;
    }
    
}

.meni-title {
    width: 30%;
    text-align: left;
    margin-left: 0;
    margin-right: 1rem;
}

.meni-line {
    width: 50%;
    max-width: 50%;
    padding: 0 0.5rem;
    margin: 0;
}

.meni-line hr{
    width: 100%;    
    border: 1px solid var(--mainColor);
    height: 30px;
    border-style: solid;
    border-color: var(--mainColor);
    border-width: 1px 0 0 0;
    border-radius: 20px;
}
.meni-line hr:before {
    display: block;
    content: "";
    height: 30px;
    margin-top: -31px;
    border-style: solid;
    border-color: var(--mainColor);
    border-width: 0 0 1px 0;
    border-radius: 20px;
}
@media screen and (max-width: 1000px) {  
    .meni-line {
        width: 50%;
    }
}
.meni-cena {
    width: 20%;
    margin: 0;
    color: var(--mainColor);
}

.meni-cena h4{
    padding-right: 0.4rem;
}

.meni-content p{
    margin: 0;
    width: 80%;
    text-align: left;
}

.meni-breakline {
    display: none;
    border: 2px solid var(--mainColor);
    margin: 3rem auto;
}

.meni-breakline.show{
    display: block;
    width: 50%;
}
/* ----------------------ALERGENI---------------------------- */
.alergeni-wrapper{
    min-height: 30vh;
}

.alergeni h3 {
    margin: 0 auto;
    font-family: var(--fontFamily-toplar);
    font-size: 3.5rem;
}
@media screen and (min-width: 2560px) {
    .alergeni-wrapper{
        width: 50vw;
    }
}
.alergeni-content {
    flex-grow: 4;
    width: 25%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

@media screen and (max-width: 1000px) {
    .alergeni-container {
        width: 100%;
        padding: 1rem;
    }
    .alergeni-title{
        width: 50%;
    }
    .alergeni-content {
        flex-grow: 2;
        width: 45%;
    }
    .alergeni h3 {
        font-size: 2.5rem;
    }
        
}
/* -------------------Buttons---------------------- */
.buttons-container {
    position: absolute;
    top: 60vh;
    width: 100%;
    z-index: 1;
}

.buttons-container nav{
    width: 40vw;
    background-color: var(--transparentBlackColor);
    padding: 0rem;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}


.buttons-container nav ul{
    display: flex;
    flex-direction: row;
	margin-bottom: 0;
	list-style: none;
	padding-left: 0;
}

.buttons-container nav ul li {
    width: 100%;
    padding-left: 0;
}

.buttons-container nav ul li:first-child {
        border-left: 0;
    }

    .buttons-container nav ul li button {
        width: 100%;
        display: block;
        min-width: 10rem;
        justify-content: center;
        height: 2.75rem;
        line-height: 2.75rem;
        list-style: none;
        letter-spacing: 0.2rem;
        font-size: 1.1rem;
        text-align: left;
        font-family: var(--fontFamily-toplar);
        border-bottom: 0;
        list-style: none;
        background-color: var(--transparentBlackColor);
        color: var(--mainColor);
        border: solid 1px var(--mainColor);;
        border-radius: 4px;
        padding: 0.2rem 1rem 0.2rem 2rem;
    }

    .buttons-container nav ul li:hover {
            background-color: rgba(255, 255, 255, 0.6);
        }

        .buttons-container nav ul li button:hover {
            color: var(--mainColor);
            cursor: pointer;
            
        }

        .buttons-container nav ul li button:active {
            background-color: rgba(255, 255, 255, 0.175);
        }

        .buttons-container nav ul li button i{
            font-size: 1.5rem;
            padding-right: 1rem;
        }
@media screen and (max-width: 1700px) {
    .buttons-container nav{
        width: 60vw;
    }
}
@media screen and (max-width: 1150px) {
            
    .buttons-container {
        top: 45vh;
    }
    .buttons-container nav{
        padding: 0rem;
    }
    .buttons-container nav ul{
        display: flex;
        flex-direction: column;
        border: none;
    }
        
    .buttons-container nav ul li::after{
        content: '';
        display: block;
        position: relative;
        top: 0;
        left: calc(50% - 1px);
        width: 1px;
        height: 6px;
        background: #ffffff;
    }
        
    .buttons-container nav ul li:last-child:after{
        display: none;
    }

    .buttons-container nav ul li:hover {
        background-color: var(--transparentBlackColor);
    }
    .buttons-container nav ul li button {
        text-align: left;
    }
}